<template>
  <div class="versionManagement">
    <el-scrollbar class="elScroll">
      <template v-if="$route.name=='versionManagement'">
        <div class="search_sList">
          <el-tabs v-model="activeName">
            <el-tab-pane label="版本管理" name="first"></el-tab-pane>
          </el-tabs>
          <div class="top_opera">
            <el-form :inline="true" :model="formInline" class="demo-form-inline" label-width="80px">
              <el-form-item label="平台类型">
                <el-select v-model="formInline.platformType" placeholder="请选择状态">
                  <el-option label="PC" value="0"></el-option>
                  <el-option label="安卓" value="1"></el-option>
                  <el-option label="iOS" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="版本编号">
                <el-input v-model="formInline.versionId" placeholder="版本编号" clearable></el-input>
              </el-form-item>
              <el-form-item label="版本号">
                <el-input v-model="formInline.versionNum" placeholder="版本号" clearable></el-input>
              </el-form-item>
              <el-form-item label="强制更新">
                <el-select v-model="formInline.forceUpdate" placeholder="请选择" clearable>
                  <el-option label="否" value="0"></el-option>
                  <el-option label="是" value="1"></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="状态">
                <el-select v-model="formInline.status" placeholder="选择状态" clearable>
                  <el-option label="待发布" value="0"></el-option>
                  <el-option label="已发布" value="1"></el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item label="发布时间">
                <el-date-picker
                  v-model="publishTimeVal"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="创建时间">
                <el-date-picker
                  v-model="timeVal"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  :default-time="['00:00:00', '23:59:59']">
                </el-date-picker>
              </el-form-item>
            </el-form>
            <div class="btn_area">
              <el-button class="mainBtn" @click="onSubmit" plain>搜索</el-button>
              <el-button type="info" plain @click="resetEvent">重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_sList">
          <div class="opera_btnArea">
            <el-button class="mainBtn" @click="versionEvent('add')" plain v-allow="'sys_version_add'">+添加</el-button>
            <!-- <el-button class="mainBtn" @click="batchDelete" plain v-allow="'sys_version_del'">批量删除</el-button> -->
            <el-button class="mainBtn" @click="exportEvent" plain v-allow="'sys_version_export'">导出</el-button>
          </div>
          <el-table
            :data="tableData"
            stripe
            border
            style="width: 100%"
            align="center"
            row-key='id'
            @selection-change="handleSelectionChange">
            <!-- <el-table-column
              align="center"
              type="selection"
              :reserve-selection="true"
              width="55">
            </el-table-column> -->
            <el-table-column
              prop="platformType"
              label="平台类型"
              align="center"
              :formatter="regPlatformType">
            </el-table-column>
            <el-table-column
              prop="versionId"
              label="版本编号"
              align="center">
            </el-table-column>
            <el-table-column
              prop="versionNum"
              label="版本号"
              align="center">
            </el-table-column>
            <el-table-column
              prop="packetSize"
              label="包大小(M)"
              align="center"
              width="120">
            </el-table-column>
            <el-table-column
              prop="imprint"
              label="版本说明"
              align="center">
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.imprint" placement="top" effect="light" popper-class="pubToop">
                  <div class="ellipsis">{{scope.row.imprint}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              prop="forceUpdate"
              label="强制更新"
              align="center"
              :formatter="regForceUpdate">
            </el-table-column>
            <el-table-column
              prop="downurls"
              label="下载地址"
              align="center">
              <template slot-scope="scope">
                <el-tooltip :content="scope.row.downurls" placement="top" effect="light" popper-class="pubToop">
                  <div class="ellipsis">{{scope.row.downurls || '/'}}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              label="发布时间"
              align="center"
              width="160">
              <template slot-scope="scope">
                <span>{{timestampReturnDate(scope.row.releaseTime, 'datetime') || '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="创建时间"
              align="center"
              width="160">
              <template slot-scope="scope">
                <span>{{timestampReturnDate(scope.row.createTime, 'datetime') || '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="140"
              align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="versionEvent('state',scope.row)">详情</el-button>
                <el-button type="text" @click="versionEvent('edit',scope.row)" v-allow="'sys_version_edit'">编辑</el-button>
                <!-- <el-button @click="deleteEvent(scope.row)" type="text" v-allow="'sys_version_del'">删除</el-button> -->
              </template>
            </el-table-column>
          </el-table>
          <Pagination @changePage="changePage" :childMsg="childMsg"></Pagination>
        </div>
      </template>
      <template v-else>
        <publicChildren></publicChildren>
      </template>
    </el-scrollbar>
    <dialogDiy :isShow="isShow" :dialogDiyData="dialogDiyData" @dialogEvent="dialogDiyEvent">
      <div slot="dialogContent" class="dia_opera">
        <img class="errIcon" src="../../assets/img/warn.png" alt="">
        <p>{{deleteType == 1 ? '确定要删除该版本吗？' : '确定要批量删除版本记录吗？'}}</p>
        <div class="btn_area">
          <el-button @click="batchDelete">关闭</el-button>
          <el-button class="mainBtn" @click="doEvent">确定</el-button>
        </div>
      </div>
    </dialogDiy>
    <dialogSubmit :isShowSub="isShowSub" @closeSub="closeSub"></dialogSubmit>
    <el-dialog class="compile" :title="type == 'add' ? '添加版本' : type == 'edit' ? '编辑版本' : '查看版本'" :visible.sync="isShowVer" @close='closeEvent' :close-on-click-modal="false">
      <el-form :model="form" label-width="100px" :rules="rules" ref="ruleForm" :disabled="isRead">
        <el-form-item label="平台类型" prop="platformType">
          <el-select v-model="form.platformType" placeholder="请选择状态" @change="queryVersion">
            <el-option label="PC" value="0"></el-option>
            <el-option label="安卓" value="1"></el-option>
            <el-option label="iOS" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="版本编号" prop="versionId">
          <el-input v-model.number="form.versionId" maxlength="100" placeholder="版本编号" clearable disabled></el-input>
        </el-form-item>
        <el-form-item label="版本号" prop="versionNum">
          <el-input v-model="form.versionNum" maxlength="100" placeholder="版本号" clearable></el-input>
        </el-form-item>
        <el-form-item label="包大小" prop="packetSize" v-if="form.platformType != '0'">
          <el-input maxlength="16" v-model.number="form.packetSize" placeholder="包大小" clearable>
            <template slot="append">M</template>
          </el-input>
        </el-form-item>
        <el-form-item label="版本说明">
          <el-input v-model="form.imprint" maxlength="300" placeholder="版本说明" clearable></el-input>
        </el-form-item>
        <el-form-item label="强制更新" v-if="form.platformType > '0'">
          <el-radio-group v-model="form.forceUpdate">
            <el-radio :label="0">否</el-radio>
            <el-radio :label="1">是</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="下载地址" v-if="form.platformType > '0'">
          <el-button class="uploadBtn" v-if="form.platformType == 1" @click="selectEvent">选择文件</el-button>
          <el-input v-model="form.downurls" maxlength="100" @blur="checkEvent" placeholder="下载地址" clearable></el-input>
        </el-form-item>
        <el-form-item label="发布时间" prop="releaseTime">
          <el-date-picker
            v-model="form.releaseTime"
            type="datetime"
            value-format="timestamp"
            placeholder="选择发布时间">
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="状态">
          <el-select v-model="form.status" placeholder="请选择状态" clearable>
            <el-option label="待发布" value="0"></el-option>
            <el-option label="已发布" value="1"></el-option>
          </el-select>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="!isRead" @click="isShowVer = false">取 消</el-button>
        <el-button v-if="!isRead" type="primary" @click="enterEvent('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
    <input
      style="display: none"
      type="file"
      @change="picChange"
      ref="loadPic"
      id="upFile"
    />
  </div>
</template>

<script>
import publicChildren from '../publicChildren'
import Pagination from '../Pagination'
import dialogDiy from '../commonModule/dialogDiy'
import dialogSubmit from '../commonModule/dialogSubmit'
import way from '../../api/encapsulation'
import uploadAli from '../../api/uploadAli'
export default {
  name: 'versionManagement',
  components: {
    publicChildren,
    Pagination,
    dialogDiy,
    dialogSubmit,
  },
  data() {
    return {
      activeName: 'first',
      formInline: {
        createTimeEnd: null,
        createTimeStart: null,
        currentPage: 1,
        pageSize: 10,
        forceUpdate: null,
        platformType: null,
        releaseTimeEnd: null,
        releaseTimeStart: null,
        versionId: null,
        versionNum: null
      },
      tableData: [],
      loading: false,
      childMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      publishTimeVal: '',
      timeVal: '',
      idArr: [],
      moduleData: [],
      isShow: false,
      dialogDiyData: {
        title: '',
        sizeStyle: {width: '400px',height: '234px'}
      },
      deleteType: 1, // 1 单个删除， 2 批量删除
      isShowSub: false,
      isShowVer: false,
      form: {
        downurls: null,
        forceUpdate: 0,
        id: null,
        imprint: null,
        packetSize: null,
        platformType: '0',
        releaseTime: null,
        status: null,
        versionNum: null,
        versionId: 1
      },
      isRead: false,
      rules: {
        platformType: [
          { required: true, message: '请选择平台类型', trigger: 'blur' }
        ],
        // versionId: [
        //   { required: true, message: '请输入版本编号', trigger: 'blur' },
        //   { type: 'number', message: '版本编号必须为数字值'}
        // ],
        versionNum: [
          { required: true, message: '请输入版本号', trigger: 'blur' }
        ],
        releaseTime: [
          { required: true, message: '请选择发布时间', trigger: 'blur'}
        ]
      },
      type: '',
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log(newVal,oldVal)
        if(newVal.name == 'versionManagement') {
          this.getVersionTableEvent();
        }
      }
    }
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    // 获取登录日志表格数据
    getVersionTableEvent() {
      this.loading = true;
      let obj = this.formInline;
      this.$request.getVersionTable(obj, res => {
        this.loading = false;
        if(res.code == 0){
          let {records, current, size, total} = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          }
        }
      })
    },
    regForceUpdate(row) {
      let edu = String(row.forceUpdate)
      let val = '';
      switch(edu) {
        case '0':
          val = '否';
          break;
        case '1':
          val = '是';
          break;
      }
      return val;
    },
    regPlatformType(row) {
      let edu = String(row.platformType)
      let val = '';
      switch(edu) {
        case '0':
          val = 'PC';
          break;
        case '1':
          val = '安卓';
          break;
        case '2':
          val = 'iOS';
          break;
      }
      return val;
    },
    handleSelectionChange(val) {
      if(val.length > 0){
        let idArr = val.map((item, index) => {
          return item.id
        })
        this.idArr = idArr;
      } else {
        this.idArr = [];
      }
    },
    async regPayDate(row) {
      // console.log(row.payDate)
      let val = '';
      if(row.payDate) {
        console.log(666)
        val = await way.timestampReturnDate(row.payDate)
      }
      return val;
    },
    // 重置
    resetEvent() {
      this.formInline.platformType = null;
      this.formInline.versionId = null;
      this.formInline.versionNum = null;
      this.formInline.forceUpdate = null;
      this.formInline.releaseTimeStart = null;
      this.formInline.releaseTimeEnd = null;
      this.formInline.createTimeStart = null;
      this.formInline.createTimeEnd = null;
      this.publishTimeVal = null;
      this.timeVal = null;
    },
    onSubmit() {
      if(this.timeVal) {
        this.formInline.startTime = way.dateReturnTimestamp(this.timeVal[0]);
        this.formInline.endTime = way.dateReturnTimestamp(this.timeVal[1]);
      }
      if(this.publishTimeVal) {
        this.formInline.releaseTimeStart = way.dateReturnTimestamp(this.publishTimeVal[0]);
        this.formInline.releaseTimeEnd = way.dateReturnTimestamp(this.publishTimeVal[1]);
      }
      this.formInline.currentPage = 1;
      this.formInline.pageSize = 10;
      this.getVersionTableEvent();
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.currentPage = val.current;
      this.formInline.pageSize = val.size;
      this.getVersionTableEvent();
    },
    selectEvent() {
      this.$refs.loadPic.value = "";
      this.$refs.loadPic.click();
    },
    async picChange() {
      let inputDOM = this.$refs.loadPic;
      let files = inputDOM.files[0];
      if (!this.$way.checkFileType(files.name)) {
        this.Warn('请勿上传“asp、php、cgi、aspx、jsp、html”等后缀的文件')
        return
      }
      let ossObj = await uploadAli.uploadOss(files, 'apk');
      this.form.downurls = ossObj.excelUrl;
    },
    checkEvent() {
      if (['asp', 'php', 'cgi', 'aspx', 'jsp', 'html'].includes(this.form.downurls)) {
        this.form.downurls = null
        this.Warn('请勿填写带有“asp、php、cgi、aspx、jsp、html”等后缀')
      }
    },
    // 导出
    exportEvent() {
      if(this.idArr.length == 0) {
        this.Warn('请选择需要导出的数据');
        return;
      }
      let ids = this.idArr;
      this.$request.exportVersion({ids}).then(res=> {
        if(res.data.code == 0) {
          this.Success(res.data.data);
          this.isShowSub = true;
          // this.$nextTick(()=>{
          // this.idArr = [];
          // console.log(this.idArr)

          // })
        }
      })
    },
    // 批量删除
    batchDelete() {
      this.deleteType = 2;
      this.isShow = !this.isShow;
    },
    dialogDiyEvent(val) {
      this.isShow = val.isShow;
    },
    // 删除
    deleteEvent(row) {
      this.deleteType = 1;
      this.rowStatus = row;
      this.isShow = !this.isShow;
    },
    doEvent() {
      if(this.deleteType == 1) {
        this.onlyDelete();
      } else {
        this.moreDelete();
      }
    },
    // 单个删除执行事件
    onlyDelete() {
      let id = this.rowStatus.id;
      this.$request.deleteByVerId({id}, res => {
        if(res.code == 0) {
          this.isShow = !this.isShow;
          this.rowStatus = '';
          this.Success(res.msg);
          this.formInline.currentPage = 1;
          this.getVersionTableEvent();
        }
      })
    },
    // 批量删除执行事件
    moreDelete() {
      if(this.idArr.length == 0) {
        this.Warn('未选择需批量删除的数据');
        return;
      }
      let ids = this.idArr;
      this.$request.deleteByVerIdList({ids}).then( res => {
        if(res.data.code == 0) {
          this.isShow = !this.isShow;
          this.Success(res.data.msg);
          this.formInline.currentPage = 1;
          this.getVersionTableEvent();
        }
      })
    },
    closeSub(val) {
      this.isShowSub = val.isShow;
    },
    versionEvent(type, row = {}) {
      this.type = type;
      this.isShowVer = !this.isShowVer;
      this.isRead = type == 'state' ? true : false;
      if(type != 'add') {
        this.watchState(row)
      } else {
        this.queryVersion(0);
      }
    },
    enterEvent(formName) {
      let dto = this.form;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.type == 'add') {
            this.$request.addVersion(dto, res => {
              if(res.code == 0) {
                this.Success(res.msg);
                this.isShowVer = false;
                this.clearEvent();
                this.getVersionTableEvent();
              }
            })
          } else {
            this.$request.editVersion(dto).then(res=>{
              if(res.data.code == 0) {
                this.Success(res.data.msg);
                this.isShowVer = false;
                this.clearEvent();
                this.getVersionTableEvent();
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    // 详情
    watchState(row) {
      this.$request.queryVersionById({id:row.id}, res=>{
        if(res.code == 0){
          this.form.downurls = res.data.downurls;
          this.form.forceUpdate = res.data.forceUpdate || 0;
          this.form.id = res.data.id;
          this.form.imprint = res.data.imprint;
          this.form.packetSize = res.data.packetSize;
          this.form.platformType = this.regData(res.data.platformType);
          this.form.releaseTime = res.data.releaseTime;
          this.form.status = this.regData(res.data.status);
          this.form.versionNum = res.data.versionNum;
          this.form.versionId = res.data.versionId;
        }
      })
    },
    // 数据处理
    regData(val) {
      let newVal = '';
      if(String(val) != 'null') {
        newVal = String(val);
      }
      return newVal;
    },
    closeEvent() {
      this.isShowVer = false;
      this.isRead = false;
      this.clearEvent();
    },
    // clear
    clearEvent() {
      this.$refs['ruleForm'].resetFields()
      this.form.downurls = null
      this.form.forceUpdate = 0
      this.form.id = null
      this.form.imprint = null
      this.form.packetSize = null
      this.form.platformType = '0'
      this.form.releaseTime = null
      this.form.status = null
      this.form.versionNum = null
      this.form.versionId = 1
    },
    // 查询版本编号
    queryVersion(type) {
      this.$request.queryVersionByType({ type }, res => {
        if(res.code == 0) {
          this.form.versionId = res.data + 1;
        }
      })
    }
  },
}
</script>

<style lang="less">
  .versionManagement {
    height: 100%;
    .elScroll {
      height: 100%;
      .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    .search_sList {
      background-color: #fff;
      padding: 28px 40px;
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
        }
        .btn_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      .opera_btnArea {
        margin-bottom: 20px;
      }
      // ::-webkit-scrollbar {
      //   width: 8px;
      //   height: 8px;
      //   background-color: #fff;
      // }
      // ::-webkit-scrollbar-thumb {
      //     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
      //     background-color: rgba(0, 0, 0, .1);
      //     border-radius: 3px;
      //     border-top-right-radius: 4px;
      //     border-bottom-right-radius: 4px;
      // }
      // .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
      //     border-right: none;
      // }
      .el-pagination {
        text-align: right;
      }
    }
    .dia_opera {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;
      padding: 30px;
      height: 100%;
      .errIcon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
    .compile {
      .el-dialog {
        width: 400px;
      }
      .uploadBtn {
        margin-bottom: 10px;
      }
    }
  }
  .pubToop {
    max-width: 600px;
  }
</style>